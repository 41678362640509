import { createUcapan, getUcapan } from "../../api/ucapan";
import { setLoading } from "./global";
// import {guardAPI} from '@/hooks';

export const getAllDataUcapan = () => async (dispatch: any) => {
  try {
    const response = await getUcapan();
    dispatch({ type: "GET_DATA_UCAPAN", value: response.data });
  } catch (error: any) {
    dispatch({ type: "GET_DATA_UCAPAN", value: [] });
  }
};

export const createDataUcapan = (value: any) => async (dispatch: any) => {
  dispatch(setLoading(true));
  try {
    const response = await createUcapan(value);
    dispatch({
      type: "CREATE_DATA_UCAPAN_SUCCESS",
      value: response?.status,
    });
    dispatch(setLoading(false));
    alert("Ucapan telah dikirim");
  } catch (error: any) {
    const data = error?.response?.status;
    dispatch({ type: "CREATE_DATA_UCAPAN_ERROR", value: data });
    dispatch(setLoading(false));
  }
};
