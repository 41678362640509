import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ChikitaZahrul from "./pages/ChikitaZahrul";
import queryString from "query-string";
import store from "./redux/store";
import { Provider } from "react-redux";

interface IndexProps {
  to?: string;
  type?:number
}

const QureyParams: React.FC = () => {
  const { search } = useLocation();
  const params = queryString.parse(search) as IndexProps;
  return <ChikitaZahrul {...params} />;
};

function App() {
  return (
    <div>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/chikita-zahrul" element={<QureyParams />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
