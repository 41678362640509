import React, { useState } from "react";
import CardEwalet from "../CardEwallet";
interface TheWeddingofProps {
  to?: string;
  onClick?: any;
}
export default function Gift({ to, onClick }: TheWeddingofProps) {
  const [modal, setModal] = useState<boolean>(false);
  const kado = {
    nama: "Chikita Dian Rahma S",
    alamat:
      "Jalan MH Thamrin Gang 4 RT 03 RW 05 Sambak, Danyang, Purwodadi, Kabupaten Grobogan, Jawa Tengah 58113",
  };

  const handleCopyToClipboard = async () => {
    const textToCopy = `Nama Penerima: ${kado.nama}, Alamat: ${kado.alamat}`;
    try {
      await navigator.clipboard.writeText(textToCopy);
      //   setCopySuccess("Copied successfully!");
      alert("Copied successfully!");
      //   setTimeout(() => setCopySuccess(""), 2000); // Clear message after 2 seconds
    } catch (err) {
      //   setCopySuccess("Failed to copy!");
      console.error("Failed to copy text: ", err);
    }
  };

  const handleClick = () => {
    setModal(!modal);
  };

  return (
    <div>
      <div className="relative w-full overflow-hidden">
        {/* <img
          src="/HD1.jpg"
          alt="background"
          className="absolute inset-0 z-0 object-cover w-full h-full"
        /> */}
        <div className="absolute inset-0 bg-colorNight"></div>
        <div className="relative z-20 flex items-center justify-center h-full text-center text-white">
          <div className="w-screen min-h-screen py-10">
            <div className="w-full px-2 py-6 bg-colorNight">
              <div className="flex flex-col justify-center w-full ">
                <div className="flex flex-col justify-center px-4 py-4 mx-auto space-y-4 border-4 border-white rounded-lg border-spacing-2 md:w-3/4 w-4/4 bg-colorTaupe bg-opacity-60">
                  <div className="w-1/3 mx-auto opacity-50">
                    <img
                      src="https://res.cloudinary.com/doep8vas5/image/upload/v1718687045/wedding-invitation/chikita-zahrul/orn-couple-7_gu7g4c.png"
                      alt=""
                    />
                  </div>
                  <p className="pb-4 my-4 text-3xl border-b-2">Wedding Gift</p>
                  <div className="border-b-2 ">
                    <div>
                      <p className="text-2xl">⁌ Amplop Digital ⁌</p>
                      <p className="text-xl ">
                        Doa Restu Anda merupakan karunia yang sangat berarti
                        bagi kami. Dan jika memberi adalah ungkapan tanda kasih
                        Anda, Anda dapat memberi kado secara cashless.
                      </p>
                      <div className="mt-4">
                        <button
                          onClick={handleClick}
                          className="px-4 py-2 bg-colorNight"
                        >
                          Kirim Amplop Digital
                        </button>
                      </div>
                    </div>
                    <div className="flex items-center justify-center my-5">
                      <div className="w-full border-b border-white"></div>
                      <div className="mx-4">atau</div>
                      <div className="w-full border-b border-white"></div>
                    </div>
                    <div>
                      <p className="text-2xl">⁌ Kado ⁌</p>
                      <p className="text-xl text-left">
                        Nama Penerima : Chikita Dian Rahma S
                      </p>
                      <p className="text-xl text-left">
                        Alamat: Jalan MH Thamrin Gang 4 RT 03 RW 05 Sambak,
                        Danyang, Purwodadi, Kabupaten Grobogan, Jawa Tengah
                        58113
                      </p>
                      <div className="mt-4">
                        <button
                          className="px-4 py-2 mb-4 bg-colorNight"
                          onClick={handleCopyToClipboard}
                        >
                          Salin Alamat
                        </button>
                        {/* {copySuccess && (
                          <p className="text-green-500">{copySuccess}</p>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {modal && <CardEwalet onClick={handleClick} />}
      </div>
    </div>
  );
}
