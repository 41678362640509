const initUcapanState = {
  dataUcapan: [],
  createUcapan: "",
};

export const ucapanReducer = (state = initUcapanState, action: any) => {
  switch (action.type) {
    case "GET_DATA_UCAPAN": {
      return { ...state, dataUcapan: action.value };
    }
    case "CREATE_DATA_UCAPAN_SUCCESS": {
      return { ...state, createUcapan: action.value };
    }
    case "CREATE_DATA_UCAPAN_ERROR": {
      return { ...state, createUcapan: action.value };
    }
  }
  return state;
};
