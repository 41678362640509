import React from "react";
import { IoCloseSharp } from "react-icons/io5";

interface CardEwaletProps {
  onClick: any;
}

export default function CardEwalet({ onClick }: CardEwaletProps) {
  const text = "144701005686505";

  const handleCopyToClipboard = async (text: string) => {
    const textToCopy = text;
    try {
      await navigator.clipboard.writeText(textToCopy);
      //   setCopySuccess("Copied successfully!");
      alert("Copied successfully!");
      //   setTimeout(() => setCopySuccess(""), 2000); // Clear message after 2 seconds
    } catch (err) {
      //   setCopySuccess("Failed to copy!");
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <div className="absolute top-0 bottom-0 left-0 right-0 z-50 overflow-hidden bg-black bg-opacity-30">
      <div className="relative ">
        <div className="absolute right-0 mt-3 mr-3">
          {/* <button onClick={onClick} className="text-4xl">
            <IoCloseSharp color="white" />
          </button> */}
        </div>
        <div className="flex items-center justify-center h-screen">
          <div className="flex flex-col rounded-lg bg-colorTaupe">
            <div className="flex justify-end w-full">
              <button onClick={onClick} className="text-4xl">
                <IoCloseSharp color="white" />
              </button>
            </div>
            <div className="flex flex-row items-center w-full mx-2 my-6 space-x-4 md:mx-8">
              <div className="w-14 md:w-20">
                <img
                  src="https://res.cloudinary.com/doep8vas5/image/upload/v1718688674/wedding-invitation/chikita-zahrul/bri_x9vkfr.png"
                  alt=""
                />
              </div>
              <div className="flex flex-col items-center justify-center space-y-4">
                <div className="p-4 text-center bg-white rounded-lg bg-opacity-40">
                  <div className="text-xl font-semibold text-white font-urbanist">
                    144701005686505
                  </div>
                  <div className="text-xl font-semibold text-white font-urbanist">
                    a/n: Chikita Dian Rahma S
                  </div>
                </div>
                <button
                  onClick={() => handleCopyToClipboard(text)}
                  className="w-full px-4 py-2 text-white rounded-lg bg-colorNight"
                >
                  Salin
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
