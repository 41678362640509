import React from "react";
import { IoPaperPlane } from "react-icons/io5";
import CountdownTimer from "../CounterTimer";
import { Link, useSearchParams } from "react-router-dom";
interface TheWeddingofProps {
  direction?: "left" | "right";
}
export default function Acara({ direction = "right" }: TheWeddingofProps) {
  const dataAkad = {
    title: "Akad Nikah Chikita & Zahrul",
    startDateTime: "20240628T080000",
    endDateTime: "20240628T110000",
    location:
      "Rumah Mempelai Wanita | Jalan MH Thamrin Gang 4 RT 03 RW 05 Sambak, Danyang, Purwodadi",
    details:
      "Hai, Kamu diundang pada acara akad nikah kami | Akad Nikah Chikita & Zahrul | Jumat, 28 Juni 2024",
  };

  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const dataResepsi = {
    title: "Respesi Chikita & Zahrul",
    startDateTime: type === "2" ? "20240629T130000" : "20240629T120000",
    endDateTime: type === "2" ? "20240629T140000" : "20240629T130000",
    location:
      "Gedung Serba Guna Dewi Sri Purwodadi | Jl. MH. Thamrin, Area Sawah, Karanganyar, Kec. Purwodadi, Kabupaten Grobogan, Jawa Tengah 58113",
    details:
      "Hai, Kamu diundang pada acara pesta pernikahan kami | Pernikahan Chikita & Zahrul | Sabtu, 29 Juni 2024",
  };

  const googleCalendarUrlAkad = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
    dataAkad.title
  )}&dates=${dataAkad.startDateTime}/${
    dataAkad.endDateTime
  }&location=${encodeURIComponent(
    dataAkad.location
  )}&details=${encodeURIComponent(dataAkad.details)}`;

  const googleCalendarUrlResepsi = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
    dataResepsi.title
  )}&dates=${dataResepsi.startDateTime}/${
    dataResepsi.endDateTime
  }&location=${encodeURIComponent(
    dataResepsi.location
  )}&details=${encodeURIComponent(dataResepsi.details)}`;

  return (
    <div>
      <div className="relative w-full overflow-hidden">
        <div className="absolute inset-0 bg-colorTaupe"></div>
        <div className="relative px-2">
          <h1 className="mb-8 text-6xl italic text-center text-white">
            Hari yang ditunggu
          </h1>
          <div>
            <div className="flex flex-col justify-center font-satify">
              <span className="my-3 text-6xl text-center text-white ">
                Akad Nikah
              </span>
            </div>
            <div className="flex flex-col items-center justify-center mb-4 md:flex-row-reverse">
              <CountdownTimer
                direction="right"
                date={"2024-06-28T08:00:00+07:00"}
                url={googleCalendarUrlAkad}
              />
              <div
                className={`grid flex-row items-center w-3/4 grid-cols-12 min-h-max overflow-auto bg-colorNight ${
                  direction === "right"
                    ? "rounded-b-lg md:rounded-br-none md:w-1/2 md:rounded-l-lg"
                    : "rounded-b-lg md:rounded-bl-none md:w-1/2 md:rounded-r-lg"
                } bg-opacity-30`}
              >
                {/* <div className="flex items-center justify-center h-auto col-span-12 py-0 pt-1 pb-2 md:col-span-2 md:py-2 md:pt-0 md:h-full md:pb-0 bg-colorNight md:w-full ">
                <div className="flex flex-row items-start justify-center w-full px-1 mx-16 space-x-2 text-3xl font-bold text-white rotate-0 md:transform transform-none md:-rotate-90 font-satify">
                  <span>Akad</span>
                  <span>Nikah</span>
                </div>
              </div> */}
                <div className="w-full col-span-12 px-4 py-2 space-y-2 md:col-span-12 md:py-6 md:space-y-4">
                  <div className="flex flex-col pb-4 border-b">
                    <div className="flex items-center justify-around text-white font-urbanist">
                      <div className="text-6xl font-bold ">28</div>
                      <div>
                        <div className="flex flex-col text-lg font-bold font-urbanist">
                          <span>JUMAT</span>
                          <span>JUNI</span>
                          <span>2024</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col mt-4 ">
                    <div className="text-3xl font-semibold text-white font-urbanist">
                      07.30-selesai
                    </div>
                    <div className="mt-4 ">
                      <p className="text-sm font-semibold text-white font-urbanist">
                        LOKASI ACARA:
                      </p>
                      <p className="text-white font-urbanist">
                        Jalan MH Thamrin Gang 4 RT 03 RW 05 Sambak, Danyang,
                        Purwodadi
                      </p>
                    </div>
                  </div>
                  <Link
                    target="_blank"
                    to={"https://maps.app.goo.gl/HheQjBSJ2JUEYyzV8"}
                    className="block w-full px-4 py-2 text-white bg-colorNight hover:bg-colorTaupe hover:text-white"
                  >
                    <div className="flex flex-row items-center justify-center space-x-2">
                      <div>
                        <IoPaperPlane />
                      </div>
                      <div className="font-urbanist">Lihat Lokasi</div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col justify-center font-satify">
              <span className="my-3 text-6xl text-center text-white ">
                Resepsi Pernikahan
              </span>
            </div>
            <div className="flex flex-col items-center justify-center mb-4 md:flex-row ">
              <CountdownTimer
                url={googleCalendarUrlResepsi}
                direction="left"
                date={
                  type === "2"
                    ? "2024-06-29T13:00:00+07:00"
                    : "2024-06-29T12:00:00+07:00"
                }
              />
              <div
                className={`grid flex-row items-center w-3/4 grid-cols-12 min-h-max overflow-auto bg-colorNight ${
                  direction !== "right"
                    ? "rounded-b-lg md:rounded-br-none md:w-1/2 md:rounded-l-lg"
                    : "rounded-b-lg md:rounded-bl-none md:w-1/2 md:rounded-r-lg"
                } bg-opacity-30`}
              >
                {/* <div className="flex items-center justify-center order-first h-auto col-span-12 py-0 pt-1 pb-2 md:order-last md:col-span-2 md:py-2 md:h-full md:pt-0 md:pb-0 bg-colorNight ">
                <div className="flex flex-row items-start justify-center w-full px-1 mx-16 space-x-2 text-4xl font-bold text-white rotate-0 font-satify transform-none md:transform md:rotate-90">
                  <span>Resepsi</span>
                  <span>Pernikahan</span>
                </div>
              </div> */}
                <div className="w-full col-span-12 px-4 py-2 space-y-2 md:col-span-12 md:py-6 md:space-y-4">
                  <div className="flex flex-col pb-4 border-b">
                    <div className="flex items-center justify-around text-white">
                      <div className="text-6xl font-bold font-urbanist">29</div>
                      <div>
                        <div className="flex flex-col text-lg font-bold font-urbanist">
                          <span>SABTU</span>
                          <span>JUNI</span>
                          <span>2024</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col mt-4 ">
                    <div className="text-3xl font-semibold text-white font-urbanist">
                      {type === "2" ? "13.00-14.00" : "12.00-13.00"}
                    </div>
                    <div className="mt-4 ">
                      <p className="text-sm font-semibold text-white font-urbanist">
                        LOKASI ACARA:
                      </p>
                      <p className="text-white font-urbanist">
                        Gedung Serbaguna Dewi Sri Purwodadi Jalan MH Thamrin,
                        Danyang, Purwodadi
                      </p>
                    </div>
                  </div>
                  <Link
                    to={"https://maps.app.goo.gl/LGgXjYdaeFNEjbar9"}
                    target="_blank"
                    className="block w-full px-4 py-2 text-white bg-colorNight hover:bg-colorTaupe hover:text-white"
                  >
                    <div className="flex flex-row items-center justify-center space-x-2">
                      <div>
                        <IoPaperPlane />
                      </div>
                      <div className="font-urbanist">Lihat Lokasi</div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
