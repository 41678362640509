export default function DoaAwal() {
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-1 bg-colorNight md:col-1">
        <div className="w-full h-full mt-2 bg-colorTaupe rounded-tr-xl"></div>
      </div>
      <div className="col-span-10 py-5 bg-colorNight md:cod-span-10 rounded-b-xl">
        <div className="flex flex-col justify-center">
          <div className="w-3/4 mx-auto mb-4 md:w-1/2">
            <img
              src="https://res.cloudinary.com/doep8vas5/image/upload/v1718688534/wedding-invitation/chikita-zahrul/Bismillah-min1_rbqave.png"
              alt="background"
              className="object-cover w-full"
            />
          </div>
          <div className="px-3 text-lg text-center text-white md:text-2xl">
            <p>Assalamualaikum Warahmatullahi Wabarakatuh</p>
            <p>
              Dengan memohon Rahmat dan Ridho Allah, kami yang berbahagia akan
              menjalankan Perintah Allah dalam suatu ikatan pernikahan
            </p>
          </div>
        </div>
      </div>
      <div className="bg-colorNight col-1 md:col-1">
        <div className="w-full h-full mt-2 bg-colorTaupe rounded-tl-xl"></div>
      </div>
    </div>
  );
}
