import React from "react";
import { BiChevronDown } from "react-icons/bi";
import { RiErrorWarningLine } from "react-icons/ri";
import { useState } from "react";

type Props = {
  label?: string;
  name: string;
  type: string;
  value: any;
  onChange?: any;
  required?: boolean;
  disabled?: boolean;
  children?: any;
  placeholder?: string;
  classname?: string;
  password?: boolean;
  onClick?: any;
  autoComplete?: any;
  error?: string;
  onFocus?: any;
};

const Input = ({
  label,
  name,
  type,
  value,
  onChange,
  required = false,
  disabled = false,
  children,
  placeholder,
  classname,
  autoComplete,
  error = "",
  onFocus,
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const style = `w-full px-4 py-4 bg-gray-200 rounded-md outline-0 focus:bg-white focus:outline-1 focus:outline-double hover:shadow-md hover:bg-gray-100 ${
    error !== "" && "border border-primaryRed"
  } ${classname}`;
  return (
    <div className="flex-col gap-1 flex-cs">
      <label>{label}</label>
      <div className="relative w-full bg-none h-min">
        {type === "select" && (
          <>
            <BiChevronDown className="absolute w-6 h-6 top-0 right-0 -translate-x-[5px] translate-y-[7px] pointer-events-none" />
            <select
              className={`${style} appearance-none`}
              name={name}
              value={value}
              onChange={onChange}
              required={required}
              disabled={disabled}
            >
              {children}
            </select>
          </>
        )}
        {type === "date" && (
          <input
            className={style}
            type="date"
            name={name}
            value={value}
            onChange={onChange}
            required={required}
            disabled={disabled}
            placeholder={placeholder}
          ></input>
        )}
        {type === "textarea" && (
          <textarea
            className={`${style} min-h-[124px] resize-none`}
            style={{ verticalAlign: "top" }}
            name={name}
            value={value}
            onChange={onChange}
            required={required}
            disabled={disabled}
            placeholder={placeholder}
          ></textarea>
        )}
        {type === "password" && (
          <div className="relative mb-2 flex-cc">
            <input
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              className={style}
              onChange={onChange}
              required={required}
              disabled={disabled}
              placeholder={placeholder}
              autoComplete={autoComplete}
              type={showPassword ? "text" : "password"}
            />
            <button
              type="button"
              className="absolute right-0 mr-5 cursor-pointer"
              onMouseDown={() => {
                setShowPassword(true);
              }}
              onMouseUp={() => {
                setShowPassword(false);
              }}
              onMouseMove={() => {
                setShowPassword(false);
              }}
            >
              <img
                alt="seen"
                src={showPassword ? "/eye.svg" : "/eye-off.svg"}
              />
            </button>
          </div>
        )}
        {type !== "select" &&
          type !== "textarea" &&
          type !== "password" &&
          type !== "date" && (
            <input
              className={style}
              type={type}
              name={name}
              value={value}
              onChange={onChange}
              required={required}
              disabled={disabled}
              placeholder={placeholder}
              onFocus={onFocus}
            ></input>
          )}
        {error !== "" && (
          <>
            <RiErrorWarningLine className="absolute w-6 h-6 top-0 right-0 -translate-x-[7px] translate-y-[9px] pointer-events-none text-primaryRed" />
            <p className="ml-3 text-sm text-primaryRed">{error}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default Input;
