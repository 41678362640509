export default function DoaAkhir() {
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-1 pb-1 bg-colorNight md:col-1">
        <div className="w-full h-full mt-b bg-colorTaupe rounded-br-xl"></div>
      </div>
      <div className="col-span-10 py-5 bg-colorNight md:cod-span-10 rounded-t-xl">
        <div className="flex flex-col justify-center">
          <div className="px-3 text-lg text-center text-white md:text-2xl">
            <p className="mb-4">
              Merupakan kehormatan serta kebahagiaan bagi kami sekeluarga
              apabila Bapak/Ibu/Saudara/i berkenan hadir untuk memberikan doa
              restu kepada kedua mempelai.
            </p>
            <p>Wassalamualaikum Warahmatullahi Wabarakatuh</p>
          </div>
        </div>
      </div>
      <div className="pb-1 bg-colorNight col-1 md:col-1">
        <div className="w-full h-full mb-2 bg-colorTaupe rounded-bl-xl"></div>
      </div>
    </div>
  );
}
