import React from "react";
interface TheWeddingofProps {
  to?: string;
  onClick?: any;
}
export default function TheWeddingof({ to, onClick }: TheWeddingofProps) {
  return (
    <div>
      <div className="relative w-full h-screen overflow-hidden">
        <img
          src="https://res.cloudinary.com/doep8vas5/image/upload/v1718687083/wedding-invitation/chikita-zahrul/1_nlrf1m.png"
          alt="background"
          className="absolute inset-0 z-10 object-cover w-full h-full opacity-15"
        />
        <div className="absolute inset-0 z-0 bg-colorTaupe"></div>
        <div className="relative z-20 flex items-center justify-center h-full text-center text-white">
          <div className="w-screen py-10 space-y-10 min-h-max">
            <div>
              <span className="mb-4 text-2xl font-light text-white">
                <i>The Wedding of</i>
              </span>
              <div className="flex flex-col items-center justify-center mb-4 space-x-3 text-6xl md:flex-row text-white">
                <div>Chikita & Zahrul</div>
              </div>
            </div>
            <div className="flex justify-center ">
              <div className="flex justify-center w-3/4 my-4 bg-colorNight bg-opacity-25 border-4 border-black rounded-t-full rounded-b-xl border-spacing-3">
                <img
                  src="https://res.cloudinary.com/doep8vas5/image/upload/v1718687103/wedding-invitation/chikita-zahrul/kz327_ix8khg.png"
                  alt="couple"
                  className="inset-0 z-0 object-cover duration-200"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
