import React from "react";
import { IoMailOutline } from "react-icons/io5";
interface ForWhoProps {
  to?: string;
  onClick?: any;
  classname?: string;
}
export default function ForWho({ to, onClick, classname }: ForWhoProps) {
  return (
    <div className={classname}>
      <div className="relative w-full h-screen overflow-hidden">
        <img
          src="https://res.cloudinary.com/doep8vas5/image/upload/v1718687078/wedding-invitation/chikita-zahrul/HD1_jyijwx.jpg"
          alt="background"
          className="absolute inset-0 z-0 object-cover w-full h-full"
        />
        <div className="absolute inset-0 z-10 bg-opacity-30 bg-colorTaupe"></div>
        <div className="relative z-20 flex items-center justify-center h-full text-center text-white">
          <div className="w-screen">
            <span className="mb-4 text-base font-bold text-white">
              Welcome to The Wedding of
            </span>
            <div className="flex flex-col items-center justify-center mb-4 space-x-3 text-6xl text-white md:flex-row">
              <div>Chikita</div>
              <div className="text-2xl">&</div>
              <div>Zahrul</div>
            </div>
            {/* {to && (
              <div>
                <span className="mb-6 text-lg text-white">Dear</span>
                <p className="text-4xl font-medium text-white">{to}</p>
              </div>
            )} */}
          </div>
          <div className="absolute bottom-0 left-0 right-0 mb-10">
            <div className="flex items-end justify-center mb-5 ">
              <div className="flex flex-col justify-center space-y-4">
                {to && (
                  <div className="px-4 py-2 rounded-sm bg-slate-50 opacity-65 min-w-80">
                    <span className="mb-6 text-lg font-light text-colorNight">
                      <i>Dear</i>
                    </span>
                    <p className="text-2xl font-medium text-colorNight">{to}</p>
                  </div>
                )}
                <button
                  onClick={onClick}
                  className="px-4 py-2 mx-auto font-bold bg-white rounded-sm max-w-max text-stone-600 hover:text-white hover:bg-stone-600"
                >
                  <div className="flex flex-row items-center justify-center space-x-2">
                    <div>
                      <IoMailOutline />
                    </div>
                    <div>Buka Undangan</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
