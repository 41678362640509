import React, { useEffect, useRef } from "react";

interface TheWeddingofProps {
  to?: string;
  onClick?: any;
}

export default function Galeri({ to, onClick }: TheWeddingofProps) {
  const images = [
    "https://res.cloudinary.com/doep8vas5/image/upload/v1718687056/wedding-invitation/chikita-zahrul/foto-1_qr8acl.jpg",
    "https://res.cloudinary.com/doep8vas5/image/upload/v1718687056/wedding-invitation/chikita-zahrul/foto-4_wnqluf.jpg",
    "https://res.cloudinary.com/doep8vas5/image/upload/v1718687052/wedding-invitation/chikita-zahrul/foto-3_t2xd9p.jpg",
    "https://res.cloudinary.com/doep8vas5/image/upload/v1718687050/wedding-invitation/chikita-zahrul/foto-2_mxhh74.jpg",
    "https://res.cloudinary.com/doep8vas5/image/upload/v1718687050/wedding-invitation/chikita-zahrul/foto-2_mxhh74.jpg",
    "https://res.cloudinary.com/doep8vas5/image/upload/v1718687047/wedding-invitation/chikita-zahrul/foto-6_gjdnbm.jpg",
    // Add more images as needed
  ];

  const galleryRef = useRef<HTMLDivElement>(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  useEffect(() => {
    if (galleryRef.current) {
      // Duplicate images to create an infinite loop effect
      const totalWidth = galleryRef.current.scrollWidth;
      galleryRef.current.scrollLeft = totalWidth / 2;

      // Auto-scroll
      const autoScroll = () => {
        if (galleryRef.current) {
          galleryRef.current.scrollLeft += 1;
        }
      };

      const scrollInterval = setInterval(autoScroll, 20); // Adjust the speed here
      return () => clearInterval(scrollInterval);
    }
  }, []);

  const handleMouseDown = (event: React.MouseEvent) => {
    isDragging.current = true;
    startX.current = event.pageX - galleryRef.current!.offsetLeft;
    scrollLeft.current = galleryRef.current!.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDragging.current = false;
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (!isDragging.current) return;
    event.preventDefault();
    const x = event.pageX - galleryRef.current!.offsetLeft;
    const walk = (x - startX.current) * 2; // Adjust the scroll speed
    galleryRef.current!.scrollLeft = scrollLeft.current - walk;
  };

  const handleScroll = () => {
    const gallery = galleryRef.current;
    if (gallery) {
      const totalWidth = gallery.scrollWidth;
      const halfWidth = totalWidth / 2;
      if (gallery.scrollLeft >= totalWidth - gallery.offsetWidth) {
        gallery.scrollLeft = halfWidth - gallery.offsetWidth;
      } else if (gallery.scrollLeft <= 0) {
        gallery.scrollLeft = halfWidth;
      }
    }
  };

  return (
    <div className="flex items-center justify-center px-2 my-4 bg-colorTaupe">
      <div className="flex flex-col items-center justify-center">
        <h1 className="mb-8 text-6xl italic text-center text-white">Galeri</h1>
        <div
          className="flex justify-center w-full px-2 py-8 border-gray-300 gallery-container whitespace-nowrap cursor-grab active:cursor-grabbing"
          ref={galleryRef}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onScroll={handleScroll}
          style={{
            overflowX: "scroll",
            scrollbarWidth: "none" /* Firefox */,
            msOverflowStyle: "none" /* Internet Explorer 10+ */,
          }}
        >
          {[...images, ...images].map((src, index) => (
            <img
              key={index}
              className="w-1/2 mx-2 transition-transform duration-200 hover:scale-105"
              src={src}
              alt={`Images ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
