import React, { useEffect, useState } from "react";
import {
  Acara,
  DoaAkhir,
  DoaAwal,
  Footer,
  ForWho,
  Galeri,
  Gift,
  PlayButton,
  Profile,
  TheWeddingof,
  Ucapan,
} from "../components";

interface WeddingProps {
  to?: string;
}
export default function ChikitaZahrul({ to }: WeddingProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [welcomeMessage, setWelcomeMessage] = useState(
    "Welcome to wedding Chikita & Zahrul"
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [description, setDescription] = useState(
    "welcome the wedding of Chikita & Zahrul"
  );
  document.title = welcomeMessage;

  // Function to update the meta description
  const updateMetaDescription = (content: string) => {
    let metaTag = document.querySelector('meta[name="description"]');
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "description");
      document.head.appendChild(metaTag);
    }
    metaTag.setAttribute("content", content);
  };

  // Update the meta description when the description state changes
  useEffect(() => {
    updateMetaDescription(description);
  }, [description]);

  const [cover, setCover] = useState(true);
  const [hidden, setHidden] = useState(false);
  const [play, setPlay] = useState<string>("");

  setTimeout(() => {
    if (!cover) {
      setHidden(true);
    }
  }, 350);

  return (
    <main className="flex justify-center bg-black">
      <div className="max-w-screen-sm bg-colorTaupe">
        <div
          className={`z-40 max-w-screen-sm transition-transform duration-300 ${
            !hidden ? "visible" : "hidden"
          } ${cover ? "translate-x-0" : "-translate-y-full "}`}
        >
          <ForWho
            to={to}
            onClick={() => {
              setCover(!cover);
              setPlay("pause");
            }}
          />
        </div>
        <div
          className={`static max-w-screen-sm  duration-400 transition-opacity ${
            !cover ? "visible" : "hidden"
          }`}
        >
          <PlayButton
            onPlay={play}
            classname="fixed bottom-0 z-40 w-full mb-6 ml-6"
          />
          <TheWeddingof />
          <DoaAwal />
          <Profile />
          <Acara />
          <Galeri />
          <Gift />
          <Ucapan />
          <DoaAkhir />
          <Footer />
        </div>
      </div>
    </main>
  );
}
