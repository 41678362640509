import { combineReducers } from "redux";

import { ucapanReducer } from "./ucapan";
import { globalReducer } from "./global";

const reducers = combineReducers({
  ucapanReducer,
  globalReducer,
});

export default reducers;
