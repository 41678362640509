import React from "react";
interface TheWeddingofProps {
  to?: string;
  onClick?: any;
}
export default function Profile({ to, onClick }: TheWeddingofProps) {
  return (
    <div>
      <div className="relative w-full min-h-screen overflow-hidden">
        {/* <img
          src="/HD1.jpg"
          alt="background"
          className="absolute inset-0 z-0 object-cover w-full h-full"
        /> */}
        <div className="absolute inset-0 bg-colorTaupe"></div>
        <div className="relative z-20 flex items-center justify-center h-full text-center text-white">
          <div className="w-screen py-10 min-h-max">
            <div>
              <div className="flex flex-col justify-center">
                <div className="text-2xl">
                  <p className="text-white">The Bride</p>
                  <p className="text-6xl text-white">Chikita</p>
                </div>
                <div className="flex justify-center ">
                  <div className="flex justify-center w-3/4 my-4 overflow-hidden bg-opacity-25 border-4 border-black rounded-t-full rounded-b-full bg-colorNight border-spacing-3">
                    <img
                      src="https://res.cloudinary.com/doep8vas5/image/upload/v1718687056/wedding-invitation/chikita-zahrul/kz328_vracvg.png"
                      alt="couple"
                      className="inset-0 z-0 object-cover duration-200 scale-x-[-1]"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center mb-4 space-x-3 text-2xl text-white md:text-4xl md:flex-row">
                <div className="px-2">
                  <p className="mb-2 font-bold text-white">
                    Chikita Dian Rahma Saphira
                  </p>
                  <p className="text-xl font-semibold md:text-2xl">
                    Putri dari Bapak Eko Budi Santoso, S.Ars dan Ibu Setyo Tri
                    Andjari, S.T, M.Eng., S.Ars.
                  </p>
                </div>
              </div>
            </div>
            <div className="my-10 text-5xl text-white">&</div>
            <div>
              <div className="flex flex-col justify-center">
                <div className="text-2xl">
                  <p className="text-white">The Groom</p>
                  <p className="text-6xl text-white">Zahrul</p>
                </div>
                <div className="flex justify-center ">
                  <div className="flex justify-center w-3/4 my-4 overflow-hidden bg-opacity-25 border-4 border-black rounded-t-full rounded-b-full bg-colorNight border-spacing-3">
                    <img
                      src="https://res.cloudinary.com/doep8vas5/image/upload/v1718687056/wedding-invitation/chikita-zahrul/kz329_yxuitu.png"
                      alt="couple"
                      className="inset-0 z-0 object-cover duration-200 scale-x-[-1]"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center mb-4 space-x-3 text-2xl text-white md:text-4xl md:flex-row">
                <div className="px-2">
                  <p className="mb-2 font-bold text-white">
                    Zahrul Zizki Dinanto
                  </p>
                  <p className="text-xl font-semibold md:text-2xl">
                    Putra dari Bapak Dr. Sunanto, S.ST., M.P. dan Ibu Diyah Epik
                    Suryantini, S.ST., M.A.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
