import React, { useCallback, useEffect, useState } from "react";

import { IoPauseSharp, IoPlaySharp } from "react-icons/io5";
interface PlayButtonProps {
  classname?: string;
  onPlay?: string;
}
export default function PlayButton({ classname, onPlay }: PlayButtonProps) {
  const audioRef = React.useRef<HTMLAudioElement | null>(null);

  if (!audioRef.current) {
    audioRef.current = new Audio(
      "https://res.cloudinary.com/doep8vas5/video/upload/v1718687704/wedding-invitation/chikita-zahrul/muara-adera_u818og.mp3"
    );
    audioRef.current.loop = true; // Enable looping
  }

  const [play, setPlay] = useState("play");

  const [isPlaying, setIsPlaying] = useState(false);

  // Toggle play/pause
  const togglePlayPause = useCallback(() => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        audioRef.current
          .play()
          .then(() => {
            setIsPlaying(true);
            setPlay("pause");
            // console.log("Audio is playing!");
          })
          .catch((error) => {
            console.error("Error playing the audio file", error);
            setIsPlaying(false);
            setPlay("play");
          });
      } else {
        audioRef.current.pause();
        setIsPlaying(false);
        setPlay("play");
      }
    }
  }, []);

  const handleClick = useCallback(() => {
    togglePlayPause();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [togglePlayPause, play]);

  useEffect(() => {
    if (onPlay === "pause") {
      handleClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPlay]);

  return (
    <div className={classname}>
      <button onClick={handleClick} className="p-3 bg-white rounded-full">
        {isPlaying ? <IoPauseSharp size={20} /> : <IoPlaySharp size={20} />}
      </button>
    </div>
  );
}
