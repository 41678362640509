import React, { useCallback, useEffect, useState } from "react";
import { IoCalendar } from "react-icons/io5";
import { Link } from "react-router-dom";
interface CounterTimerProps {
  date: "2024-06-28T12:00:00+07:00" | string;
  direction?: "left" | "right";
  url: string;
}

export default function CountdownTimer({
  date,
  direction,
  url,
}: CounterTimerProps) {
  const calculateTimeLeft = useCallback(() => {
    const targetDate = new Date(date);
    const now = new Date();
    const nowUTC = new Date(
      now.toLocaleString("en-US", { timeZone: "Asia/Jakarta" })
    );
    const difference = targetDate.getTime() - nowUTC.getTime();

    return new Date(difference);
  }, [date]);

  const [timeLeft, setTimeLeft] = useState<Date>(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  const dataTimes = [
    {
      time: "Days",
      getDate: timeLeft.getUTCDate() - 1,
    },
    {
      time: "Hours",
      getDate: timeLeft.getUTCHours(),
    },
    {
      time: "Minutes",
      getDate: timeLeft.getUTCMinutes(),
    },
    {
      time: "Seconds",
      getDate: timeLeft.getUTCSeconds(),
    },
  ];

  return (
    <div className="w-3/4 md:w-1/2 bg-colorTaupe">
      <div
        className={`flex flex-col items-center justify-center w-full py-6 mx-auto space-y-4 text-white bg-colorNight ${
          direction === "right"
            ? `rounded-t-lg md:rounded-tl-none md:rounded-r-lg`
            : "rounded-t-lg md:rounded-tr-none md:rounded-l-lg"
        }`}
      >
        <div className="flex flex-col items-center justify-center space-y-8 ">
          <div className="grid grid-cols-2 gap-4 px-4">
            {dataTimes.map((dataTime: any, index: number) => (
              <div className="p-4 text-center bg-white rounded-lg bg-opacity-20">
                <span className="block text-4xl font-urbanist">
                  {dataTime.getDate}
                </span>
                <p className="text-xl font-urbanist">{dataTime.time}</p>
              </div>
            ))}
          </div>
          <Link
            to={url}
            target="_blank"
            className="block px-2 py-2 font-medium bg-white text-colorTaupe hover:text-colorTaupe hover:bg-white border-y-2"
          >
            <div className="flex items-center justify-center space-x-3">
              <IoCalendar />
              <div className="font-bold font-urbanist">Save the Date</div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
