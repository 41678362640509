import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 py-3 bg-colorNight md:cod-span-12 ">
        <div className="flex flex-col justify-center">
          <div className="px-3 text-lg text-center text-white md:text-2xl">
            <p>
              Powered by{" "}
              <Link target="_blank" to={"https://badarudd.in"}>
                {" "}
                badarudd.in
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
