import axios from "axios";
import Navigate from "../utils/navigare";
import { localStorageMixins } from "../mixins/localStorage.mixin";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "*/*",
  },
});

instance.defaults.headers.common["api-key"] = process.env.API_KEY ?? "";
// instance.interceptors.request.use;

instance.interceptors.request.use(
  async (config: any) => {
    const token = JSON.parse(localStorage.getItem("access_token") as string);
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // temporary status 403 for auto logout
    if (error.response.status === 401) {
      localStorageMixins.remove("access_token");
      localStorageMixins.remove("user_profile");
      localStorage.clear();
      Navigate("/login");
    }
    return Promise.reject(error);
  }
);

export default instance;
