import React, { useEffect, useState } from "react";
import Input from "../Input";
import { useSelector, useDispatch } from "react-redux";
import { getAllDataUcapan, createDataUcapan } from "../../redux/actions";
import { formatDate } from "../../utils";

interface TheWeddingofProps {
  to?: string;
  onClick?: any;
}

export interface UcapanType {
  nama: string;
  message: string;
  createdDate: string;
}

export default function Ucapan({ to, onClick }: TheWeddingofProps) {
  const [createData, setCreateData] = useState({
    nama: "",
    message: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newVal: any = { ...createData };
    newVal[e.target.name] = e.target.value;
    setCreateData(newVal);
  };

  const [datas, setDatas] = useState([]);

  //   const dataUcapanMap: UcapanType[] = [
  //     {
  //       nama: "Badaruddin Luthfi",
  //       message: "Selamat",
  //       createdDate: "20/12/2023",
  //     },
  //     {
  //       nama: "Udin",
  //       message: "Wihh",
  //       createdDate: "21/12/2023",
  //     },
  //     {
  //       nama: "Udin",
  //       message: "Wihh",
  //       createdDate: "21/12/2023",
  //     },
  //   ];

  const dispatch: any = useDispatch();
  const { dataUcapan, createUcapan } = useSelector(
    (state: any) => state.ucapanReducer
  );
  const { isLoading } = useSelector((state: any) => state.globalReducer);

  const submitUcapan = async (data: any) => {
    let newData = { ...data };

    if (newData.nama === "") {
      newData.nama = "no Name";
    }

    if (newData.message === "") {
      alert("Ucapan harus diisi");
    }

    if (newData.message !== "") {
      dispatch(createDataUcapan(newData));
    }
  };

  const fetchDataTable = () => {
    dispatch(getAllDataUcapan());
  };

  const manageDataTable = () => {
    const data =
      dataUcapan?.map((res: any) => ({
        id: res.id ?? "",
        message: res.message ?? "",
        nama: res.nama ?? "",
        createdDate: res.created_at ?? "",
      })) || [];

    setDatas(data);
  };

  useEffect(() => {
    fetchDataTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createUcapan, isLoading]);

  useEffect(() => {
    manageDataTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUcapan]);

  return (
    <div>
      <div className="relative w-full overflow-hidden">
        {/* <img
          src="/HD1.jpg"
          alt="background"
          className="absolute inset-0 z-0 object-cover w-full h-full"
        /> */}
        <div className="absolute inset-0 mb-10 bg-colorNight"></div>
        <div className="relative z-20 flex items-center justify-center h-full text-center text-white">
          <div className="w-screen py-10 min-h-fit">
            <div className="w-full px-2 py-6 border-black bg-colorTaupe roundde-b-full ">
              <div className="flex flex-col items-center justify-center w-full">
                <div className="mb-4 text-3xl italic text-white">
                  Ucapan dan Doa
                </div>
                <div className="flex items-center justify-center w-full md:w-3/4">
                  <div
                    className={`flex flex-col justify-center w-full px-4 py-4 mx-auto space-y-4 rounded-lg bg-colorNight ${
                      datas.length === 0 ? "pb-10" : "pb-0"
                    }`}
                  >
                    <div>
                      <Input
                        classname="text-xl font-bold text-black"
                        type="text"
                        name={"nama"}
                        value={createData.nama}
                        onChange={handleChange}
                        placeholder="Nama Lengkap..."
                      />
                      <Input
                        classname="text-lg font-semibold text-black"
                        type="textarea"
                        name={"message"}
                        value={createData.message}
                        onChange={handleChange}
                        placeholder="Tulis ucapan dan doa"
                      />
                    </div>
                    <div className="w-full">
                      <button
                        disabled={isLoading ? true : false}
                        onClick={() => submitUcapan(createData)}
                        className={`w-full px-4 py-2 rounded-md ${
                          isLoading ? "bg-slate-300 " : " bg-stone-600"
                        } `}
                      >
                        {isLoading ? "Loading..." : "Kirim"}
                      </button>
                    </div>
                    {datas.length > 0 && (
                      <div className="text-right font-urbanist">
                        Total ucapan : {datas.length > 0 ? datas.length : 0}
                      </div>
                    )}
                    <div className="grid grid-cols-1 overflow-y-auto min-h-56 max-h-56">
                      <div className="w-full space-y-4 text-black max-h-min">
                        {datas ? (
                          datas.length > 0 ? (
                            datas.map((data: any, index: number) => (
                              <div
                                className="flex flex-col py-2 bg-gray-200 rounded-md"
                                key={index}
                              >
                                <div className="flex justify-between px-2 py-1">
                                  <div className="text-xl font-extrabold text-left md:text-3xl">
                                    {data.nama}
                                  </div>
                                  <div className="text-xl font-medium text-right">
                                    {formatDate(data.createdDate, "DD-MM-yyyy")}
                                  </div>
                                </div>
                                <div className="px-2 py-1">
                                  <div className="text-xl font-medium text-left">
                                    {data.message}
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div></div>
                          )
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
